<template>
  <div class="content">
    <div class="row">
      <img :src="this.regionHeaderImage" :alt="this.$store.state.user.regionName" class="img-fluid region-header-image">
    </div>
    <div class="justify-content-center region-header-stripe-row" :style="`background-image: url('${regionHeaderStripeImage}');`">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-4 text-center region-card-image">
              <CardData rotate />
            </div>
            <div class="col-12 col-lg-7 offset-lg-1 ps-lg-5">

            </div>
          </div>
        </div>
      </div>
    <div class="container">
      <div class="row justify-content-center mt-5">
        <div class="col-12 col-lg-12">
          <h1>Die <span class="fw-bold">{{ this.$store.state.user.cardName }}</span> - Gemeinsam für unsere Stadt</h1>
          <div class="row mt-4">
            <div class="col-12 col-lg-6">
              <h4>Vielen Dank, dass Sie sich angemeldet haben!</h4>
              <strong>Lassen Sie uns bitte den letzten Schritt auch gleich machen.</strong><br />
              <br />
              Damit es losgeht, klicken Sie bitte erneut auf <router-link :to="{ name: 'PartnerWerden' }">Jetzt mitmachen</router-link>.<br />
              Dazu brauchen Sie natürlich die Konditionen, die ich Ihnen unten angefügt habe.<br />
              <p class="d-inline-flex gap-1 mt-3">
                <a data-bs-toggle="collapse" href="#collapseConditionsPartner" role="button" aria-expanded="false" aria-controls="collapseConditionsPartner">
                  Akzeptanzpartner Konditionen &gt;&gt;
                </a>
                &nbsp;&nbsp;
                <a data-bs-toggle="collapse" href="#collapseConditionsEmployer" role="button" aria-expanded="false" aria-controls="collapseConditionsEmployer">
                  Arbeitgeber Konditionen &gt;&gt;
                </a>
              </p>
              <div class="collapse mt-2" id="collapseConditionsPartner">
                <div class="card card-body">
                  <img :src="this.conditionsImagesPartner" alt="Konditionen Akzeptanzpartner" class="img-fluid">
                  <a :href="this.conditionsImagesPartner" target="_blank" class="mt-1 text-center">vergrößert im neuen Tab öffnen</a>
                </div>
              </div>
              <div class="collapse mt-2" id="collapseConditionsEmployer">
                <div class="card card-body">
                  <img :src="this.conditionsImagesEmployer" alt="Konditionen Arbeitgeber" class="img-fluid">
                  <a :href="this.conditionsImagesEmployer" target="_blank" class="mt-1 text-center">vergrößert im neuen Tab öffnen</a>
                </div>
              </div>
              <br />
              Ich freue mich auf Ihre Teilnahme bei der {{ this.$store.state.user.cardName }}!<br />
              <br />
              Herzlichst,<br />
              <span v-html="this.contactPersonGreeting"></span> <span v-html="this.contactPersonName"></span>

              <div class="row mt-5 justify-content-center">
                <div class="col-auto">
                  <router-link :to="{ name: 'PartnerWerden' }" class="btn btn-primary btn-lg ms-lg-4 mt-4 mt-lg-0">Jetzt mitmachen &gt;&gt;</router-link>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 text-center">
              <img :src="this.contactPersonImage" :alt="this.contactPersonName" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 pb-4 mt-5 text-center">
          <h2>Die erste CARD für <span class="fw-bold">alle {{ this.regionPersonsName }} </span></h2>
        </div>
        <div class="col-12 col-lg-6">
          <h5>Welche Vorteile hat die {{ this.$store.state.user.cardName }} für alle Händler, Dienstleister, Gastronomen?</h5>
          <ol>
            <li>Sie gibt’s als Card und als App. Die {{ this.regionPersonsName }} haben sie immer in der Hosentasche.</li>
            <li>Händler, Dienstleister und Gastronomen können Angebote und Aktionen via App, Newsletter und Push-Nachricht direkt aufs Handy kommunizieren.</li>
            <li>Sie ist Geschenk-, Gutschein-, Bonus- und MitarbeiterCARD in einem.</li>
            <li>Nur den Barcode scannen: Super einfaches Handling am POS ohne neue Geräte oder Software. Dank Händler-App und Web-Portal oder API-Schnittstelle.</li>
            <li>Abwicklung über deutschen Dienstleister, top seriös von Support über Technik bis Daten.</li>
            <li>Geldauszahlung regelmäßig alle zwei Wochen direkt aufs Geschäftskonto.</li>
            <li>Vertrag in 3 Minuten abschließen und wenn gewünscht, einfach und jederzeit wieder kündigen. Keine Vertragsbindung!</li>
          </ol>
          <div class="text-center mt-3"><router-link :to="{ name: 'PartnerFAQ' }" class="fw-bold">FAQ Akzeptanzpartner &gt;&gt;</router-link></div>
        </div>
        <div class="col-12 col-lg-6">
          <h5>Welche Vorteile hat die {{ this.$store.state.user.cardName }} für alle Arbeitgeber?</h5>
          <ol>
            <li>Wertschätzung und Bindung Ihrer Beschäftigten durch steuer- und abgabenfreie Sachzuwendung über die {{ this.$store.state.user.cardName }}.</li>
            <li>Stärkung und Aufwertung Ihres Images und Ihrer Arbeitgebermarke</li>
            <li>Eigene MitarbeiterCARDs mit eigenem Branding.</li>
            <li>Regelmäßig wiederaufladbar. Ob Monatlich, vierteljährlich oder wann auch immer. Sie entscheiden!</li>
            <li>Verwaltung Ihrer MitarbeiterCARDs direkt über Ihr eigenes Online-Portal. Einfach und schnell.</li>
            <li>Automatische Aufladungen Ihrer MitarbeiterCARDs zu den gewünschten Terminen. Abrechnung und Zahlung per Firmen-SEPA oder Rechnung.</li>
            <li>Vertrag in 3 Minuten abschließen und wenn gewünscht, einfach und jederzeit wieder kündigen. Keine Vertragsbindung!</li>
          </ol>
          <div class="text-center mt-3"><router-link :to="{ name: 'EmployerFAQ' }" class="fw-bold">FAQ Arbeitgeber &gt;&gt;</router-link></div>
        </div>
      </div>
    </div>
    <div class="row-full-width bg-light-gray py-5 mt-5 card-primary-color" v-if="isInterested">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2>Persönliches Gespräch? Natürlich gern.</h2>
          </div>
          <div class="col-12 col-md-6">
            <p class="fw-bold">Sie können einfach ein persönliches Gespräch mit Jasmin Bachert von trolleymaker ausmachen. Jasmin betreut mit ihrem Team unsere Teilnehmer persönlich.</p>
            <router-link :to="{ name: 'Terminbuchung' }" class="btn btn-primary me-4">Beratungstermin vereinbaren &gt;&gt;</router-link>
          </div>
          <div class="col-12 col-md-3">
            <img class="img-fluid" :src="require('../assets/img/partner-info/jasmin-bachert.jpeg')">
          </div>
        </div>
      </div>
    </div>
    <div class="row-full-width py-1 py-md-5 bg-light-gray" v-if="isInterested">
      <div class="container">
        <div class="row mt-5">
          <div class="col-12">
            <h1><span class="fw-bold">Sie haben noch eine Frage?</span> - Schreiben Sie uns!</h1>
            <div class="mt-0 mt-sm-3">
              <div class="row">
                <div class="col-12 col-lg-6">
                  <label for="inputFirstName" class="form-label label-view">
                    Firma
                  </label>
                  <input type="text" id="inputCompanyName" class="form-control" v-model="contactFormData.companyName" placeholder="Firmennamen eingeben">
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-lg-6">
                  <label for="inputTitle" class="form-label label-view">
                    Anrede
                  </label>
                  <div class="row">
                    <div class="col-lg-4">
                      <select class="form-select input-view" v-model="contactFormData.salutation" id="inputSalutation">
                        <option value="Herr">Herr</option>
                        <option value="Frau">Frau</option>
                        <option value="Divers">Divers</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-lg-6">
                  <label for="inputFirstName" class="form-label label-view">
                    Name*
                  </label>
                  <input type="text" id="inputName" class="form-control" v-model="contactFormData.name" placeholder="Namen eingeben" required>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-12 col-lg-6">
                  <label for="inputMail" class="form-label label-view">
                    E-Mail-Adresse*
                  </label>
                  <input type="email" id="inputMail" class="form-control" v-model="contactFormData.email" placeholder="E-Mail-Adresse eingeben" required>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-12 col-lg-6">
                  <label for="inputMail" class="form-label label-view">
                    Telefonnummer
                  </label>
                  <input type="text" id="inputPhone" class="form-control" v-model="contactFormData.phone" placeholder="Telefonnummer eingeben">
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-lg-6">
                  <label for="inputTitle" class="form-label label-view">
                    Bitte wählen Sie ein Thema aus*
                  </label>
                  <div class="row">
                    <div class="col-lg-6">
                      <select class="form-select input-view" v-model="contactFormData.subject" id="inputSubject">
                        <option value="Partnerkonto/Buchungserfassung">Partnerkonto/Buchungserfassung</option>
                        <option value="Abrechnung/Rechnung">Abrechnung/Rechnung</option>
                        <option value="Sonstiges">Sonstiges</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-lg-12">
                  <label for="inputMessage" class="form-label">Mitteilung*</label>
                  <textarea class="form-control" id="inputMessage" v-model="contactFormData.message" rows="7" placeholder="Nachricht eingeben"></textarea>
                </div>
              </div>
              <div class="row pt-3">
                <div class="col-lg-12">
                  Die Übermittlung der Daten erfolgt über eine verschlüsselte Verbindung. Wir verwenden Ihre Daten ausschließlich zur Bearbeitung Ihrer Anfrage. Weitere Informationen zur Verarbeitung Ihrer Daten und Widerrufshinweise finden Sie in der Datenschutzerklärung dieser Webseite. <router-link :to="{ name: 'DataProtection' }">Die Datenschutzerklärung finden Sie hier</router-link>.
                </div>
              </div>
              <div class="mt-4 mt-sm-2">
                <button type="submit" @click="sendContactForm" class="btn btn-primary">Senden &gt;&gt;</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardData from '@/components/CardData.vue'
import UserRoles from '../enums/UserRoles'

export default {
  name: 'PartnerInfoPage',
  components: {
    CardData
  },
  data () {
    return {
      contactPersonName: '',
      contactPersonGreeting: '',
      contactPersonImage: '',
      regionHeaderImage: '',
      regionHeaderStripeImage: '',
      regionPersonsName: '',
      conditionsImagesPartner: '',
      conditionsImagesEmployer: '',
      contactFormData: {
        salutation: '',
        name: '',
        companyName: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      }
    }
  },
  methods: {
    async fetchRegionData () {
      this.$store.commit('SET_LOADING_STATUS', true)

      const fields = ['acf.contact_person', 'acf.image_region_contact_person', 'acf.image_region_header', 'acf.partner_conditions_image', 'acf.employer_conditions_image', 'acf.personenbezeichnung', 'acf.image_region_header_stripe', 'acf.greeting']

      await this.$store.dispatch('getRegionDataThroughBackend', { fields: fields }).then(response => {
        this.contactPersonName = response.contact_person
        this.contactPersonGreeting = response.greeting
        this.contactPersonImage = response.image_region_contact_person
        this.regionHeaderImage = response.image_region_header
        this.regionHeaderStripeImage = response.image_region_header_stripe
        this.regionPersonsName = response.personenbezeichnung
        this.conditionsImagesPartner = response.partner_conditions_image
        this.conditionsImagesEmployer = response.employer_conditions_image
      }).finally(() => {
        this.$store.commit('SET_LOADING_STATUS', false)
      })
    },
    sendContactForm () {
      this.$store.commit('SET_LOADING_STATUS', true)
      this.$store.dispatch('interestContactForm', { salutation: this.contactFormData.salutation, name: this.contactFormData.name, companyName: this.contactFormData.companyName, contactEmail: this.contactFormData.email, phone: this.contactFormData.phone, subject: this.contactFormData.subject, message: this.contactFormData.message })
        .then(() => {
          // clear form
          this.contactFormData.salutation = ''
          this.contactFormData.name = ''
          this.contactFormData.companyName = ''
          this.contactFormData.email = ''
          this.contactFormData.phone = ''
          this.contactFormData.subject = ''
          this.contactFormData.message = ''
        }).catch(() => {})
        .finally(() => { this.$store.commit('SET_LOADING_STATUS', false) })
    }
  },
  computed: {
    isInterested () {
      return this.$store.state.user.role === UserRoles.INTERESTED
    }
  },
  created () {
    // this.fetchPartnerFAQs()
    this.fetchRegionData()
  }
}
</script>

<style scoped>
.region-header-image {
  min-height: 750px;
  max-height: 90vh;
  object-fit: cover;
}

.region-header-stripe-row {
  position: relative;
  z-index: 3;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  margin-top: -50px;
}

.region-card-image {
  margin-top: -40px;
}
</style>
