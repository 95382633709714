<template>
 <div class="container py-1 py-md-5">
  <div class="content pt-0">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-4 text-center mb-5 mg-lg-0">
        <a href="/login">
          <img :src="require(`../assets/img/cards/${cardName}.png`)" class="img-fluid w-50"/>
        </a>
      </div>
      <div class="col-12 col-lg-8">
        <div class="row justify-content-center justify-content-lg-start text-center text-lg-start">
          <h1><span class="fw-bold">Registrierung</span> als Arbeitgeber</h1>
          <p>Hier können Sie Ihren Arbeitgebervertrag abschließen</p>
          <div v-if="currentStep === 1">
            <h3>Firmendaten (für Vertrag und Rechnung)</h3>
              <div class="pt-2">
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyName" class="form-label label-view">Firmenname (ggf. mit Rechtsform)</label>
                      <input v-model="registrationData.companyName" type="text" id="companyName" class="form-control input-view" placeholder="Firmennamen eingeben">
                      <div id="companyNameNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyAddressAdditional" class="form-label label-view">Adresse-Zusatz</label>
                      <input v-model="registrationData.companyAddressAdditional" type="text" id="companyAddressAdditional" class="form-control input-view" placeholder="Adress-Zusatz eingeben (optional)">
                      <div id="companyAddressAdditionalNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyStreet" class="form-label label-view">Straße und Hausnummer</label>
                      <input v-model="registrationData.companyStreet" type="text" id="companyStreet" class="form-control input-view" placeholder="Straße und Hausnummer eingeben">
                      <div id="companyStreetNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyZip" class="form-label label-view">Postleitzahl</label>
                      <input v-model="registrationData.companyZip" type="text" id="companyZip" class="form-control input-view" placeholder="PLZ eingeben">
                      <div id="companyZipNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyCity" class="form-label label-view">Ort</label>
                      <input v-model="registrationData.companyCity" type="text" id="companyCity" class="form-control input-view" placeholder="Ort eingeben">
                      <div id="companyCityNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyCountry" class="form-label label-view">Land</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.companyCountry" class="form-select input-view" id="companyCountry">
                            <option value="DE">Deutschland</option>
                            <option value="CH">Schweiz</option>
                            <option value="AT">Österreich</option>
                            <option value="FR">Frankreich</option>
                            <option value="BE">Belgien</option>
                            <option value="LU">Luxemburg</option>
                            <option value="DK">Dänemark</option>
                            <option value="NO">Norwegen</option>
                            <option value="SE">Schweden</option>
                            <option value="FI">Finnland</option>
                            <option value="LI">Liechtenstein</option>
                            <option value="NL">Niederlande</option>
                            <option value="PL">Polen</option>
                            <option value="PT">Portugal</option>
                            <option value="RO">Rumänien</option>
                            <option value="RU">Russland</option>
                            <option value="GR">Griechenland</option>
                            <option value="AL">Albanien</option>
                            <option value="AD">Andorra</option>
                            <option value="BG">Bulgarien</option>
                            <option value="IT">Italien</option>
                            <option value="IE">Irland</option>
                            <option value="EE">Estland</option>
                            <option value="LV">Lettland</option>
                            <option value="LT">Litauen</option>
                            <option value="KZ">Kasachstan</option>
                            <option value="SM">San Marino</option>
                            <option value="RS">Serbien</option>
                            <option value="SK">Slowakei</option>
                            <option value="SI">Slowenien</option>
                            <option value="ES">Spanien</option>
                            <option value="CZ">Tschechien</option>
                            <option value="TR">Türkei</option>
                            <option value="UA">Ukraine</option>
                            <option value="HU">Ungarn</option>
                            <option value="GB">Vereinigtes Königreich</option>
                            <option value="MT">Malta</option>
                            <option value="MC">Monaco</option>
                            <option value="MK">Nordmazedonien</option>
                          </select>
                        </div>
                      </div>
                      <div id="companyCountryNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top" v-if="this.$store.state.region.name !== 'Leinfelden-Echterdingen'">
                  <div class="row">
                    <div class="col-lg-7">
                      <label for="inputCompanyGewerbeverein" class="form-label label-view">Mitglied Gewerbeverein (Info)</label>
                      <div class="row">
                        <div class="col-lg-4">
                          <select v-model="registrationData.companyGewerbeverein" class="form-select input-view" id="companyGewerbeverein" readonly="readonly" disabled="disabled">
                            <option v-for="vereinsname in regionCheckoutData.requirement_to_join_club_names" :key="vereinsname.requirement_to_join_club_name" :value="vereinsname.requirement_to_join_club_name">{{vereinsname.requirement_to_join_club_name}}</option>
                            <option value="Kein Mitglied, möchte aber Infos">Kein Mitglied, möchte aber Infos</option>
                            <option value="Kein Mitglied">Kein Mitglied</option>
                          </select>
                        </div>
                      </div>
                      <div id="companyGewerbevereinNote" class="form-text error-note"></div>
                    </div>
                  </div>
                </div>
                <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyEmail" class="form-label label-view">E-Mail-Adresse (Rechnung/Vertrag)</label>
                    <input v-model="registrationData.companyEmail" type="email" id="companyEmail" class="form-control input-view" placeholder="E-Mail-Adresse eingeben" maxlength="70">
                    <div id="companyEmailNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCompanyEmailRepeated" class="form-label label-view">E-Mail-Adresse (Rechnung/Vertrag) wiederholen</label>
                    <input v-model="registrationData.companyEmailRepeated" type="email" id="companyEmailRepeated" class="form-control input-view" placeholder="E-Mail-Adresse wiederholen" maxlength="100">
                    <div id="companyEmailRepeatedNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <br />
              <h3>Inhaber / Geschäftsführer</h3>
              <span class="fst-italic">Dies sind keine Login-Daten und dienen nur zur Information.</span>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCeoName" class="form-label label-view">Vorname Nachname</label>
                    <input v-model="registrationData.ceoName" type="text" id="ceoName" class="form-control input-view" placeholder="Vorname Nachname des/r Geschäftsführer/in" maxlength="30">
                    <div id="ceoNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputCeoPhone" class="form-label label-view">Telefon (Inhaber/Geschäftsführer)</label>
                    <input v-model="registrationData.ceoPhone" type="text" id="ceoPhone" class="form-control input-view" placeholder="Telefonnummer des/r Geschäftsführer/in" maxlength="30">
                    <div id="ceoPhoneNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <br />
              <h3>Ansprechpartner (Admin der Firma)</h3>
              <span class="fst-italic">Dies ist Ihr ursprünglicher Interessenten-Account.<br />Für Änderungen wenden Sie sich bitte an den Support.</span>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonGender" class="form-label label-view">Geschlecht</label>
                    <div class="row">
                      <div class="col-lg-4">
                        <select :value="registrationData.contactPersonGender" class="form-select input-view" id="contactPersonGender" disabled>
                          <option value="männlich">männlich</option>
                          <option value="weiblich">weiblich</option>
                          <option value="divers">divers</option>
                          <option value="sonstige">sonstige</option>
                        </select>
                      </div>
                    </div>
                    <div id="contactPersonGenderNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonFirstName" class="form-label label-view">Vorname</label>
                    <input :value="registrationData.contactPersonFirstName" type="text" id="contactPersonFirstName" class="form-control input-view" placeholder="Vorname eingeben" maxlength="30" readonly>
                    <div id="contactPersonFirstNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonLastName" class="form-label label-view">Nachname</label>
                    <input :value="registrationData.contactPersonLastName" type="text" id="contactPersonLastName" class="form-control input-view" placeholder="Nachname eingeben" maxlength="30" readonly>
                    <div id="contactPersonLastNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <div class="pt-2 border-top">
                <div class="row">
                  <div class="col-lg-7">
                    <label for="inputContactPersonLastName" class="form-label label-view">E-Mail</label>
                    <input :value="registrationData.contactPersonEmail" type="text" id="contactPersonEmail" class="form-control input-view" maxlength="30" readonly>
                    <div id="contactPersonLastNameNote" class="form-text error-note"></div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div class="pt-2 mb-3 border-top">
                <div class="text-end">
                  <button @click="nextStep()" class="btn btn-primary ms-4">Vertrag abschließen >></button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="currentStep === 2">
            <p>Sie haben einen Gutscheincode? <span class="voucher-link" @click="onOpenVoucherClicked()"><u>Hier Gutscheincode eingeben</u></span></p>
            <div v-if="shouldShowVoucherInput">
              <div class="row">
                <div class="col-lg-7">
                  <label for="voucherCode" class="form-label label-view">Gutscheincode</label>
                  <div class="input-group">
                    <input v-model="voucherCode" type="text" id="voucherCode" class="form-control input-view" placeholder="Gutscheincode eingeben" aria-describedby="add-voucher-button">
                    <button class="btn btn-primary" id="add-voucher-button" @click="setVoucherValue()">Gutscheincode anwenden</button>
                  </div>
                  <div id="vocherCodeNote" class="form-text error-note"></div>
                </div>
              </div>
            </div>
            <div class="pt-2 mb-3 border-top">
              <div class="row">
                <div class="col-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Produktname</th>
                        <th v-if="voucherValue > 0 || voucherPercent > 0">Gutscheincode</th>
                        <th v-if="isMemberInTradeAssociation && regionHasTradeAssociationEmployerVoucher">Rabatt</th>
                        <th>Preis</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Partnervertrag MitarbeiterCARD
                          <br /><span class="fst-italic text-muted" v-html="regionCheckoutData.partner_entry_fee_description_text"></span>
                        </td>
                        <td v-if="voucherValue > 0"><span class="text-success fw-bold">Gutscheincode "{{voucherCode}}" mit {{voucherValue}}€ netto Rabatt aktiv</span></td>
                        <td v-if="voucherPercent > 0"><span class="text-success fw-bold">Gutscheincode "{{voucherCode}}" mit {{(voucherPercent * 100)}}% Rabatt aktiv</span></td>
                        <td v-if="isMemberInTradeAssociation && regionHasTradeAssociationEmployerVoucher">
                          <span class="text-success fw-bold">Partnervertrag MitarbeiterCARD Rabatt für Mitgliedschaft im Gewerbeverein:<br />{{ regionCheckoutData.employer_entry_fee_membership_discount }}€ Rabatt aktiv</span>
                        </td>
                        <td>
                          <strong>{{ priceToPay }}€</strong><br />
                          ({{ netAmount }}€ zzgl. MwSt.)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12">
                  <h3>Sonstige Hinweise zum Vertrag</h3>
                  <div class="pt-2 border-top">
                    <div class="row">
                      <div class="col-lg-12">
                        <label for="inputCeoName" class="form-label label-view">Falls Sie sonstige Hinweise zum Vertrag haben, können Sie diese hier eingeben.</label>
                        <textarea v-model="registrationData.additionalNotesForContract" type="text" id="additionalNotesForContract" class="form-control input-view" rows="4"></textarea>
                        <div id="additionalNotesForContractNote" class="form-text error-note"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <div class="form-check text-start">
                    <input v-model="registrationData.conditionsConsent" type="checkbox" class="form-check-input p-2" id="conditionsConsent">
                    <label class="form-check-label" for="conditionsConsent">
                      Ich habe die <a :href="`${this.$store.state.region.whiteLabelWebsiteUrl}partner-bedingungen/`" target="_blank">AGB, Datenschutzhinweise und Datenschutzerklärung</a> gelesen und akzeptiere sie.*
                    </label>
                    <div id="conditionsConsentNote" class="form-text error-note"></div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <p>Im Rahmen der Registrierung und Nutzung des Partnerportals verarbeiten wir Ihre Daten. Hinweise zur Verarbeitung und Nutzung finden Sie in unseren <router-link :to="{ name: 'PrivacyPolicy' }">Datenschutzhinweisen</router-link> und der <router-link :to="{ name: 'DataProtection' }">Datenschutzerkärung</router-link>.</p>
                </div>
                <div class="col-lg-12 mt-5" v-if="this.$store.state.region.name !== 'trolleymaker'">
                  <div v-if="this.priceToPay > 0" id="paypal-button-container" ref="paypalButons"></div>
                  <button v-if="this.priceToPay === 0" @click="saveRegistrationData()" class="btn btn-primary text-end">Partnervertrag MitarbeiterCARD abschließen &gt;&gt;</button>
                </div>
              </div>
            </div>
            <div class="pt-2 mt-5 border-top">

            </div>
            <div class="pt-2 mt-5 mb-3 border-top">
                <button @click="previousStep()" class="btn btn-secondary text-start">&lt;&lt; Zurück</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
import UserRolesEnum from '../enums/UserRoles'
import PartnerUserRolesEnum from '../enums/PartnerUserRoles'
import { loadScript } from '@paypal/paypal-js'
require('../assets/img/circle-minus.svg')
const slugify = require('slugify')

export default {
  name: 'EmployerRegistrationPage',
  data () {
    return {
      currentStep: 1,
      registrationData: {},
      regionCheckoutData: {},
      validationStatus: false,
      paypalLoaded: false,
      registrationDataStep1Keys: ['companyName', 'companyAddressAdditional', 'companyStreet', 'companyZip', 'companyCity', 'companyCountry', 'companyGewerbeverein', 'companyEmail', 'companyEmailRepeated', 'ceoName', 'ceoPhone'],
      registrationDataStep2Keys: ['conditionsConsent'],
      showVoucherInput: false,
      voucherValue: 0,
      voucherPercent: 0,
      voucherCode: ''
    }
  },
  computed: {
    priceToPay () {
      if ((Object.prototype.hasOwnProperty.call(this.registrationData, 'sktype') && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr mitarbeitercard')) || (this.registrationData.tmspartner && this.registrationData.tmspartner === true)) {
        return 0
      } else {
        return (this.netAmount * 1.19).toFixed(2)
      }
    },
    netAmount () {
      if ((Object.prototype.hasOwnProperty.call(this.registrationData, 'sktype') && this.registrationData.sktype.toLowerCase().includes('keine einrichtungsgebühr mitarbeitercard')) || (this.registrationData.tmspartner && this.registrationData.tmspartner === true)) {
        return 0
      }
      if (this.voucherPercent === 1) {
        return 0
      }

      let price = Number(this.regionCheckoutData.mitarbeitercard_entry_fee)

      if (this.isMemberInTradeAssociation && this.regionHasTradeAssociationEmployerVoucher) {
        price = price - Number(this.regionCheckoutData.employer_entry_fee_membership_discount)
      }

      if (this.voucherValue > 0) {
        price = price - Number(this.voucherValue)
      } else if (this.voucherPercent > 0) {
        price = price * Number(this.voucherPercent)
      }

      price = price.toFixed(2)
      return price
    },
    isMemberInTradeAssociation() {
      return typeof this.registrationData.companyGewerbeverein !== 'undefined' && this.registrationData.companyGewerbeverein !== null && this.registrationData.companyGewerbeverein.toLowerCase() !== 'kein mitglied' && this.registrationData.companyGewerbeverein.length > 0
    },
    regionHasTradeAssociationEmployerVoucher() {
      return this.regionCheckoutData.employer_entry_fee_membership_discount !== null && typeof this.regionCheckoutData.employer_entry_fee_membership_discount !== 'undefined' && this.regionCheckoutData.employer_entry_fee_membership_discount > 0
    },
    cardName () {
      return this.$store.state.region.cardName ? slugify(this.$store.state.region.cardName, { lower: true, locale: 'de', trim: true }) : 'alle-regionen-logos'
    },
    shouldShowVoucherInput () {
      return this.showVoucherInput
    }
  },
  methods: {
    async saveRegistrationData () {
      if (this.validateRegistrationData()) {
        this.$store.commit('SET_LOADING_STATUS', true)
        this.registrationData.priceToPay = this.netAmount
        this.$store.dispatch('employerRegistration', this.registrationData)
          .then(() => {
            window.scrollTo(0, 0)
            this.$store.commit('SET_LOADING_STATUS', false)
            this.$store.commit('SET_USER_ROLE', UserRolesEnum.EMPLOYER)
            this.$store.commit('SET_PARTNER_ROLE', PartnerUserRolesEnum.ADMIN)
            this.$router.push({ name: 'EmployerPersonalData' })
          }).catch(() => {
            this.$store.commit('SET_LOADING_STATUS', false)
          })
      }
    },
    validateRegistrationData () {
      this.validationStatus = true
      if (this.currentStep === 1) {
        this.registrationDataStep1Keys.forEach(key => {
          this.removeValidationError(key)
          if (this.$store.state.region.name === 'Leinfelden-Echterdingen' && key === 'companyGewerbeverein') {
            return
          }
          if (key !== 'companyAddressAdditional' && !this.registrationData[key]) {
            this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
            return
          }
          if ((key === 'companyName' || key === 'companyStreet' || key === 'companyCity' || key === 'ceoPhone') && this.registrationData[key].length < 3) {
            this.showValidationError(key, 'Das Feld muss mindestens 3 Zeichen lang sein.')
          }
          if (key === 'companyCountry' && this.registrationData[key].length < 2) {
            this.showValidationError(key, 'Ungültiger Ländercode. Bitte wenden Sie sich an den Support.')
          }
          if ((key === 'companyCity' || key === 'companyCountry' || key === 'ceoName') && !(/^[a-zA-ZäöüßÄÖÜ -]+$/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Das Feld darf nur Buchstaben enthalten.')
          }
          if (key === 'companyStreet' && !(/\d/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Es muss eine Hausnummer angegeben werden.')
          }
          if (key === 'ceoPhone' && !(/^[+]?[0-9 ]+$/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Die Telefonnummer ist ungültig. Sie darf nur Ziffern oder am Anfang das "+" für die Ländervorwahl enthalten.')
          }
          if (key === 'companyZip' && !(/^\d+$/.test(this.registrationData[key]))) {
            this.showValidationError(key, 'Dieses Feld darf nur Ziffern enthalten.')
          }
          if ((key === 'companyZip') && !(this.registrationData[key].length === 5 || this.registrationData[key].length === 4)) {
            this.showValidationError(key, 'Dieses Feld darf nur maximal 4 oder 5 Ziffern enthalten.')
          }
          if ((key === 'companyEmail' || key === 'companyEmailRepeated') && this.registrationData.companyEmail !== this.registrationData.companyEmailRepeated) {
            this.showValidationError(key, 'Die E-Mail-Adressen stimmen nicht überein.')
          }
          if (key === 'companyEmail' && !this.registrationData.companyEmail.includes('@')) {
            this.showValidationError(key, 'Dies ist keine gültige E-Mail-Adresse.')
          }
        })
      } else if (this.currentStep === 2) {
        this.registrationDataStep2Keys.forEach(key => {
          this.removeValidationError(key)
          if (key !== 'conditionsConsent') {
            this.showValidationError(key, 'Das Feld muss ausgefüllt werden.')
            return
          }
          if (key === 'conditionsConsent' && (!this.registrationData[key] || this.registrationData[key] === false)) {
            this.showValidationError(key, 'Sie müssen die AGB, Datenschutzhinweise und Datenschutzerklärung akzeptieren.')
          }
        })
      }
      return this.validationStatus
    },
    showValidationError(key, validationMessage) {
      const element = document.getElementById(key)
      if (element) {
        element.classList.add('form-element-danger')
      }
      const elementNote = document.getElementById(key + 'Note')
      if (elementNote) {
        elementNote.innerHTML = validationMessage
      }
      this.validationStatus = false
    },
    removeValidationError (key) {
      const element = document.getElementById(key)
      const elementNote = document.getElementById(key + 'Note')
      if (element) {
        element.classList.remove('form-element-danger')
      }
      if (elementNote) {
        elementNote.innerHTML = ''
      }
    },
    getIcon (imageFile) {
      return require('../assets/img/' + imageFile)
    },
    fetchCompleteInterestPersonalData () {
      return this.$store.dispatch('getCompleteInterestPersonalData')
    },
    fetchRegionPartnerCheckoutData () {
      return this.$store.dispatch('getRegionPartnerCheckoutData', { regionName: this.$store.state.region.name, regionSlug: this.$store.state.region.slug })
    },
    setVoucherValue () {
      if (typeof this.voucherCode !== 'undefined' && this.voucherCode !== '') {
        if (this.voucherCode.toLowerCase() === 'trolleymaker100') {
          this.voucherValue = 0
          this.voucherPercent = 1
          this.$store.commit('ADD_ALERT', { category: 'Info', message: 'Der Gutscheincode "' + this.voucherCode + '" wurde erfolgreich angewendet und enthält einen Rabatt von ' + (this.voucherPercent * 100) + '% netto.' })
        } else if (this.voucherCode.toLowerCase() === 'dummy') {
          this.voucherValue = 10
          this.voucherPercent = 0
          this.$store.commit('ADD_ALERT', { category: 'Info', message: 'Der Gutscheincode "' + this.voucherCode + '" wurde erfolgreich angewendet und enthält einen Rabatt von ' + this.voucherValue + '€ netto.' })
        } else {
          this.voucherValue = 0
          this.voucherPercent = 0
          this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ungültiger Gutscheincode.' })
        }
      } else {
        this.voucherValue = 0
        this.voucherPercent = 0
        this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ungültiger Gutscheincode.' })
      }
    },
    async checkIfEmailAlreadyExists(emailToCheck) {
      return await this.$store.dispatch('checkEmailExists', { inputEmail: emailToCheck }).then(response => {
        return response.exists
      }).catch(() => {
      })
    },
    deactivateForm () {
      const inputs = document.querySelectorAll('.form-control, .form-select, .form-check-input, .btn')
      inputs.forEach(inputElement => {
        inputElement.readOnly = true
        inputElement.disabled = true
      })
    },
    nextStep () {
      if (this.validateRegistrationData()) {
        this.currentStep = this.currentStep + 1
        window.scrollTo(0, 0)
      }
    },
    previousStep () {
      this.paypalLoaded = false
      this.currentStep = this.currentStep - 1
      window.scrollTo(0, 0)
    },
    onOpenVoucherClicked () {
      this.showVoucherInput = true
    },
    showPayPal () {
      if (this.priceToPay > 0) {
        loadScript({
          'client-id': 'AfFBbQ79tZTtiS9yUjS3HgL8diVR4ZjazvJ6_UoDynx5u0m9Z09YZ5MgdSpKIkeMtcyM5jvjK6CXLlJA',
          currency: 'EUR'
        })
          .then((paypal) => {
            this.paypalLoaded = true
            const that = this
            paypal
              .Buttons({
                createOrder: function (data, actions) {
                  if (that.validateRegistrationData()) {
                    that.$store.commit('DELETE_ALERTS')
                    return actions.order.create({
                      purchase_units: [{
                        amount: {
                          value: that.priceToPay,
                          currency_code: 'EUR'
                        }
                      }]
                    })
                  } else {
                    that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Es müssen zuerst die Fehler auf dieser Seite behoben werden.' })
                  }
                },
                onApprove: function (data, actions) {
                  return actions.order.capture().then(function (details) {
                    that.saveRegistrationData()
                  })
                },
                onError: function () {
                  that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Bei der Bezahlung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.' })
                },
                onCancel: function () {
                  that.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Sie müssen die Bezahlung abschließen, um Ihre Registrierung abzuschließen.' })
                }
              })
              .render('#paypal-button-container')
              .catch((error) => {
                console.error('failed to render the PayPal Buttons', error)
              })
          })
          .catch((error) => {
            console.error('failed to load the PayPal JS SDK script', error)
          })
      }
    }
  },
  created () {
    Promise.all([this.fetchCompleteInterestPersonalData(), this.fetchRegionPartnerCheckoutData()])
      .then((response) => {
        this.registrationData = response[0]
        this.regionCheckoutData = response[1][0].acf
        if (Object.prototype.hasOwnProperty.call(this.registrationData, 'sktype') && this.registrationData.sktype.toLowerCase().includes('abgeänderte einrichtungsgebühr mitarbeitercard')) {
          if (!Object.prototype.hasOwnProperty.call(this.registrationData, 'feeEmployer') || typeof this.registrationData.feeEmployer === 'undefined' || this.registrationData.feeEmployer === '') {
            this.$store.commit('ADD_ALERT', { category: 'Fehler', message: 'Ihre Einrichtungsgebühr konnte nicht ermittelt werden. Bitte kontaktieren Sie den Support.' })
            this.deactivateForm()
          } else {
            this.regionCheckoutData.mitarbeitercard_entry_fee = this.registrationData.feeEmployer.replace(' €', '').replace('€', '')
          }
        }
        this.$store.commit('SET_LOADING_STATUS', false)
      })
      .catch(() => {
        this.deactivateForm()
        this.$store.commit('SET_LOADING_STATUS', false)
      })
  },
  updated () {
    if (this.currentStep === 2 && !this.paypalLoaded) {
      this.showPayPal()
    }
  }
}
</script>

<style lang="scss" scoped>
  .label-view {
    margin-bottom: 5px;
  }

  #gender {
    min-width: 130px;
  }

  #country {
    min-width: 220px;
  }

  #companyGewerbeverein {
    min-width: 350px;
  }

  .input-view {
    border-radius: 0;
  }

  .form-control:focus, .form-select:focus {
    border: 1px solid #003882;
    box-shadow: 0 0 0 0.1rem #003882;
    padding-left: 5px;
  }

  .form-control.time-field {
    width: auto;
    display: inline-block;
  }

  .form-select {
    width: 300px;
  }

  #app .form-control:disabled,
  #app .form-control[readonly] {
    background-color: #75797c !important;
    opacity: 0.6;
  }

  .voucher-link {
    cursor: pointer;
  }
</style>
